export default "<section class='g-time-wrapper'>\n" +
"    <header class='g-head g-flex' id='g-head'>\n" +
"        <section class='g-head-content'>\n" +
"            <span class='g-current g-hour g-active g-pointer' id='g-hours'>21</span>\n" +
"            <span class='g-current'>:</span>\n" +
"            <span class='g-current g-minute g-pointer' id='g-minutes'>37</span>\n" +
"        </section>\n" +
"    </header>\n" +
"\n" +
"\n" +
"    <section class='g-clock-wrapper g-flex' id='g-clock-wrapper'>\n" +
"        <div class='g-clock' id='g-clock'>" +
"            <span class='g-middle-dot' id='g-middle-dot'></span>\n" +
"            <div class='g-hand-of-a-clock' id='g-hand-of-a-clock'></div>\n" +
"            <div class='g-clock g-clock-inner' id='g-clock-inner'></div>\n" +
"        </div>\n" +
"    </section>\n" +
"\n" +
"\n" +
"    <footer class='g-buttons g-flex' id='g-buttons'>\n" +
"        <button id='g-time-cancel' class='g-button g-cancel g-pointer'>CANCEL</button>\n" +
"        <button id='g-time-submit' class='g-button g-submit g-pointer'>OK</button>\n" +
"    </footer>\n" +
"\n" +
"</section>";